import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { camelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import { User } from "main/javascripts/types/user";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { AccountParamState } from "../types/accountParam";
import { localeSelector } from "./localeSlice";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";

const key = "accountParam";

const initialState: AccountParamState = {
  utmSource: null,
  lineRegisterUrl: null,
  lineType: null,
  researchFlightLiffId: null,
  researchHotelLiffId: null,
  errors: null,
  loading: false,
};

/** Async **/
export const fetchAccountParams = createAsyncThunk<
  {
    currentUser: User.User;
    utmSource?: string;
    lineRegisterUrl?: string;
    lineType?: string;
    researchFlightLiffId?: string;
    researchHotelLiffId?: string;
  },
  {
    param: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchAccountParams`, async (args, { rejectWithValue }) => {
  try {
    const { param } = args;
    const result = await createWrapper().get(
      `${PATH_SCOPE}/api/account_params.json?${param}`
    );
    // console.log(result);
    return result.data;
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const accountParamSlice = createSlice({
  name: key,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountParams.fulfilled, (state, action) => {
        state.utmSource = action.payload.utmSource;
        state.lineRegisterUrl = action.payload.lineRegisterUrl;
        state.lineType = action.payload.lineType;
        state.researchFlightLiffId = action.payload.researchFlightLiffId;
        state.researchHotelLiffId = action.payload.researchHotelLiffId;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: { [key]: AccountParamState }) => state[key];

export const utmSourceSelector = createSelector(
  stateSelector,
  (state) => state.utmSource
);

export const lineRegisterUrlSelector = createSelector(
  stateSelector,
  (state) => state.lineRegisterUrl
);

export const lineTypeSelector = createSelector(
  stateSelector,
  (state) => state.lineType
);

export const researchFlightLiffIdSelector = createSelector(
  stateSelector,
  (state) => state.researchFlightLiffId
);

export const researchHotelLiffIdSelector = createSelector(
  stateSelector,
  (state) => state.researchHotelLiffId
);

export const availableLineRegisterUrlSelector = createSelector(
  [utmSourceSelector, lineRegisterUrlSelector, localeSelector],
  (utmSource: any, lineRegisterUrl: any, locale: string): string | null => {
    // 日本以外サポート外
    if (locale !== "ja") {
      return null;
    }
    if ((utmSource === "line" || !utmSource) && lineRegisterUrl) {
      return lineRegisterUrl;
    }
    return null;
  }
);

export const availableFacebookMessengerRegisterUrlSelector: any =
  createSelector([utmSourceSelector], (utmSource: any): string | null => {
    if (utmSource === "facebook") {
      return "https://m.me/tabitabi.official";
    }
    return null;
  });
