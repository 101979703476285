import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { camelizeKeys, decamelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
// import { RootState } from "main/javascripts/store";
import { SearchHotel } from "main/javascripts/types/searchHotel";
import { descendingRegionsSelector } from "./hotelSlice";
import {
  initialMaxPrice as hotelInitialMaxPrice,
  initialMinPrice as hotelInitialMinPrice,
} from "main/javascripts/components/molecules/Form/HotelPriceFilterBlock/HotelPriceFilterBlock";
import { hotelIndexParamSelector } from "main/javascripts/slices/paramSlice";
import { HotelIndexParam } from "main/javascripts/types/hotelIndexParam";
import { isObjectEmpty } from "main/javascripts/utils/ObjectUtil";
import { clone } from "main/javascripts/utils/ObjectUtil";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { getUtmValues } from "../../utils/googleAnalyticsUtil";

const key = "searchHotel";

const initialState: SearchHotel = {
  conditionValues: {},
  advancedConditionValues: {},
  sortConditionValues: "",
  loading: false,
  errors: null,
};

/** Async **/
export const postSearchHotelCache = createAsyncThunk<
  {
    data: any;
  },
  {
    params?: any;
    dynamicPackageId?: string;
    searchGroupId?: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/postSearchHotelCache`, async (args, { rejectWithValue }) => {
  try {
    const { params, dynamicPackageId, searchGroupId } = args;
    const url = `${PATH_SCOPE}/api/search_hotel/cache`;
    const utmValues = getUtmValues();
    const requestParams = {
      ...utmValues,
      ...params,
    };
    const result = await createWrapper().post(
      url,
      decamelizeKeys({
        conditions: requestParams,
        dynamicPackageId,
        searchGroupId,
      })
    );
    return {
      data: result.data,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const searchHotelSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setSearchHotelConditionValues: (state, action) => {
      state.conditionValues = action.payload;
    },
    setSearchHotelAdvancedConditionValues: (state, action) => {
      // read onlyエラーが発生することがあるためcloneする
      // フォームの値を直接stateに入れてしまうとimmerによりread onlyに変更され
      // 次のフォーム更新時にエラーになったと想定している
      state.advancedConditionValues = clone(action.payload);
    },
    setSearchHotelSortConditionValues: (state, action) => {
      state.sortConditionValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: { [key]: SearchHotel }) => state[key];

export const conditionValuesSelector = createSelector(
  stateSelector,
  (state) => state.conditionValues
);

export const advancedConditionValuesSelector = createSelector(
  stateSelector,
  (state) => state.advancedConditionValues
);

export const sortConditionValuesSelector = createSelector(
  stateSelector,
  (state) => state.sortConditionValues
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

export const adjustedAdvancedValuesSelector = createSelector(
  advancedConditionValuesSelector,
  descendingRegionsSelector,
  (advancedFormValues: any, descendingRegions: any): any => {
    if (!advancedFormValues || isObjectEmpty(advancedFormValues)) {
      return advancedFormValues;
    }
    const descendingRegionIds: string[] = (descendingRegions || []).map(
      (region: any) => String(region.id)
    );

    const filteredDescendingRegions: string[] =
      advancedFormValues.descending_region_ids
        ? advancedFormValues.descending_region_ids.filter((id: string) =>
            descendingRegionIds.includes(id)
          )
        : advancedFormValues.descending_region_ids;

    return {
      ...advancedFormValues,
      descending_region_ids: filteredDescendingRegions,
    };
  }
);

export const advancedSearchFormInitialValuesSelector: any = createSelector(
  hotelIndexParamSelector,
  (param: HotelIndexParam): any => {
    return {
      min_price: param.minPrice ? param.minPrice : hotelInitialMinPrice,
      max_price: param.maxPrice ? param.maxPrice : hotelInitialMaxPrice,
      min_rating: param.minRating ? param.minRating : null,
      rank: param.rank ? param.rank : null,
      bed_count: param.bedCount ? param.bedCount : null,
      bed_size_types: param.bedSizeTypes ? param.bedSizeTypes : null,
      meal_type: param.mealType ? param.mealType : null,
      view_type: param.viewType ? param.viewType : null,
      descending_region_ids: param.descendingRegionIds
        ? param.descendingRegionIds
        : null,
    };
  }
);

/** action export **/
export const {
  setSearchHotelConditionValues,
  setSearchHotelAdvancedConditionValues,
  setSearchHotelSortConditionValues,
} = searchHotelSlice.actions;

export const convertSearchHotelFormValuesToParams = (data: any) => {
  const params = clone(data);
  if (params.guests?.length > 0) {
    params.guests.map((guest, index) => {
      if (Number(guest.number_of_children) > 0) {
        params.guests[index].ages_of_children = guest.ages_of_children.map(
          (age) => age.age
        );
      } else {
        delete params.guests[index].ages_of_children;
      }
    });
  }
  return params;
};
