import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
  // createEntityAdapter,
} from "@reduxjs/toolkit";
import { camelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { LpHotelDstRegion } from "main/javascripts/types/lpHotelDstRegion";
import { MetaTag } from "../../../types/metaTag";

const key = "lpHotelDstRegion";

const initialState: LpHotelDstRegion.LpHotelDstRegionEntityState = {
  hotelDestinationRegion: null,
  metaTags: null,
  page: 0,
  numPerPage: 0,
  totalNum: 0,
  loading: false,
  errors: null,
};

/** Async **/
export const fetchLpHotelDstRegionsShowPage = createAsyncThunk<
  {
    hotelDestinationRegion: LpHotelDstRegion.HotelDestinationRegion;
    metaTags: MetaTag;
    numPerPage: number;
    page: number;
    totalNum: number;
  },
  {
    id: number;
    param: string;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(
  `${key}/fetchLpHotelDstRegionsShowPage`,
  async (args, { rejectWithValue }) => {
    try {
      const { id, param, isServerSide = false, headers } = args;
      const url = `/api/lp/hotels/dst_regions/${id}.json?${param}`;
      const result = await createWrapper({ isServerSide }).get(url, {
        headers,
      });
      return {
        hotelDestinationRegion: result.data.hotelDestinationRegion,
        metaTags: result.data.metaTags,
        numPerPage: result.data.numPerPage,
        page: result.data.page,
        totalNum: result.data.totalNum,
      };
    } catch (err) {
      return rejectWithValue(camelizeKeys(err.response.data));
    }
  }
);

/** slice **/
export const lpHotelDstRegionSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initHotelDestination: (
      state,
      action: PayloadAction<{
        hotelDestinationRegion: LpHotelDstRegion.HotelDestinationRegion;
        metaTags: MetaTag;
        numPerPage: number;
        page: number;
        totalNum: number;
      }>
    ) => {
      state.hotelDestinationRegion = action.payload.hotelDestinationRegion;
      state.metaTags = action.payload.metaTags;
      state.numPerPage = action.payload.numPerPage;
      state.page = action.payload.page;
      state.totalNum = action.payload.totalNum;
    },
  },
  extraReducers: (builder) => {
    builder
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

const stateSelector = (state: {
  [key]: LpHotelDstRegion.LpHotelDstRegionEntityState;
}) => state[key];

export const hotelDestinationRegionSelector = createSelector(
  stateSelector,
  (state) => state.hotelDestinationRegion
);

export const pageSelector = createSelector(
  stateSelector,
  (state) => state.page
);

export const numPerPageSelector = createSelector(
  stateSelector,
  (state) => state.numPerPage
);

export const totalNumSelector = createSelector(
  stateSelector,
  (state) => state.totalNum
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const { initHotelDestination } = lpHotelDstRegionSlice.actions;
