import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
  // createEntityAdapter,
} from "@reduxjs/toolkit";
import { camelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { LpFlightDstRegion } from "main/javascripts/types/lpFlightDstRegion";
import { MetaTag } from "../../../types/metaTag";

const key = "lpFlightDstRegion";

const initialState: LpFlightDstRegion.LpFlightDstRegionEntityState = {
  flightDestinationRegion: null,
  metaTags: null,
  loading: false,
  errors: null,
};

/** Async **/
export const fetchLpFlightDstRegionsShowPage = createAsyncThunk<
  {
    flightDestinationRegion: LpFlightDstRegion.FlightDestinationRegion;
    metaTags: MetaTag;
  },
  {
    id: number;
    param: string;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(
  `${key}/fetchLpFlightDstRegionsShowPage`,
  async (args, { rejectWithValue }) => {
    try {
      const { id, param, isServerSide = false, headers } = args;
      const url = `/api/lp/flights/dst_regions/${id}.json?${param}`;
      const result = await createWrapper({ isServerSide }).get(url, {
        headers,
      });
      return {
        flightDestinationRegion: result.data.flightDestinationRegion,
        metaTags: result.data.metaTags,
      };
    } catch (err) {
      return rejectWithValue(camelizeKeys(err.response.data));
    }
  }
);

/** slice **/
export const lpFlightDstRegionSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initFlightDestination: (
      state,
      action: PayloadAction<{
        flightDestinationRegion: LpFlightDstRegion.FlightDestinationRegion;
        metaTags: MetaTag;
      }>
    ) => {
      state.flightDestinationRegion = action.payload.flightDestinationRegion;
      state.metaTags = action.payload.metaTags;
    },
  },
  extraReducers: (builder) => {
    builder
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

const stateSelector = (state: {
  [key]: LpFlightDstRegion.LpFlightDstRegionEntityState;
}) => state[key];

export const flightDestinationRegionSelector = createSelector(
  stateSelector,
  (state) => state.flightDestinationRegion
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const { initFlightDestination } = lpFlightDstRegionSlice.actions;

export const destinationTypeForSearch = (
  flightCheapestRoundTrip: LpFlightDstRegion.FlightCheapestRoundTrip
): string => {
  return flightCheapestRoundTrip.destinationType.replace("destination_", "");
};
export const originTypeForSearch = (
  flightCheapestRoundTrip: LpFlightDstRegion.FlightCheapestRoundTrip
): string => {
  return flightCheapestRoundTrip.originType.replace("origin_", "");
};
