import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { camelizeKeys, decamelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  createDeepEqualSelector,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { SearchFlight } from "main/javascripts/types/searchFlight";
import { MAX_SEARCH_FLIGHT_ITINERARY_COUNT } from "../../constants/FlightConstants";
import { FlightTypes } from "../../constants/FlightTypes";
import {
  airlinesSelector,
  departureAirportsSelector,
  transitAirportsSelector,
} from "./flightSlice";
import {
  initialMaxPrice,
  initialMinPrice,
} from "../../components/flight/form/FlightPriceFilterBlock";
import { flightIndexParamSelector } from "../../slices/paramSlice";
import { FlightIndexParam } from "../../types/flightIndexParam";
import { PATH_SCOPE } from "../../constants/Constants";
import { clone } from "main/javascripts/utils/ObjectUtil";
import { getUtmValues } from "../../utils/googleAnalyticsUtil";

const key = "searchFlight";

const initialState: SearchFlight = {
  conditionValues: {},
  advancedConditionValues: {},
  sortConditionValues: "",
  loading: false,
  errors: null,
};

/** Async **/
export const postSearchFlightCache = createAsyncThunk<
  {
    data: any;
  },
  {
    params?: any;
    dynamicPackageId?: string;
    searchGroupId?: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/postSearchFlightCache`, async (args, { rejectWithValue }) => {
  try {
    const { params, dynamicPackageId, searchGroupId } = args;
    const url = `${PATH_SCOPE}/api/search_flight/cache`;
    const utmValues = getUtmValues();
    const requestParams = {
      ...utmValues,
      ...params,
    };
    const result = await createWrapper().post(
      url,
      decamelizeKeys({
        conditions: requestParams,
        dynamicPackageId,
        searchGroupId,
      })
    );
    return {
      data: result.data,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const searchFlightSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setSearchFlightConditionValues: (state, action) => {
      state.conditionValues = action.payload;
    },
    setSearchFlightAdvancedConditionValues: (state, action) => {
      // read onlyエラーが発生することがあるためcloneする
      // フォームの値を直接stateに入れてしまうとimmerによりread onlyに変更され
      // 次のフォーム更新時にエラーになったと想定している
      state.advancedConditionValues = clone(action.payload);
    },
    setSearchFlightSortConditionValues: (state, action) => {
      state.sortConditionValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: { [key]: SearchFlight }) => state[key];

export const conditionValuesSelector = createSelector(
  stateSelector,
  (state) => state.conditionValues
);

export const advancedConditionValuesSelector = createSelector(
  stateSelector,
  (state) => state.advancedConditionValues
);

export const sortConditionValuesSelector = createSelector(
  stateSelector,
  (state) => state.sortConditionValues
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

export const shallEqualAdjustedAdvancedValuesSelector: any = createSelector(
  conditionValuesSelector,
  advancedConditionValuesSelector,
  airlinesSelector,
  departureAirportsSelector,
  transitAirportsSelector,
  (
    formValues: any,
    advancedFormValues: any,
    airlines: any,
    departureAirports: any,
    transitAirports: any
  ): any => {
    if (!advancedFormValues) {
      return advancedFormValues;
    }
    const airlineCodes: any[] = (airlines || []).map(
      (airline: any) => airline.code
    );
    const departureAirportCodes: any[] = (departureAirports || []).map(
      (airport: any) => airport.code
    );
    const transitAirportCodes: any[] = (transitAirports || []).map(
      (airport: any) => airport.code
    );

    const filteredAirlines: any = advancedFormValues.airline_codes
      ? advancedFormValues.airline_codes.filter((code: string) =>
          airlineCodes.includes(code)
        )
      : advancedFormValues.airline_codes;
    const filteredDepartureAirports: any =
      advancedFormValues.departure_airport_codes
        ? advancedFormValues.departure_airport_codes.filter((code: string) =>
            departureAirportCodes.includes(code)
          )
        : advancedFormValues.departure_airport_codes;
    const filteredTransitAirports: any =
      advancedFormValues.transit_airport_codes
        ? advancedFormValues.transit_airport_codes.filter((code: string) =>
            transitAirportCodes.includes(code)
          )
        : advancedFormValues.transit_airport_codes;

    const values: any = {
      ...advancedFormValues,
      airline_codes: filteredAirlines,
      departure_airport_codes: filteredDepartureAirports,
      transit_airport_codes: filteredTransitAirports,
    };

    const flightType: string = formValues.flight_type;
    // tslint:disable-next-line:prefer-array-literal
    Array.from(Array(MAX_SEARCH_FLIGHT_ITINERARY_COUNT).keys()).map(
      (_: any, index: number) => {
        const departure_hour: any = values[`departure_hour${index + 1}`];
        if (departure_hour) {
          if (
            flightType === FlightTypes.oneWay ||
            (flightType === FlightTypes.roundTrip && index > 0) ||
            (flightType === FlightTypes.multiCity &&
              index + 1 >= formValues.itineraries.count)
          ) {
            delete values[`departure_hour${index + 1}`];
          }
        }
        return false;
      }
    );

    return values;
  }
);

// DeepEqualで変更をチェックする
// createSelectorの比較は引数で渡すselectorsに対して実施されるようなので
// 計算結果を比較させるためにshallEqualAdjustedAdvancedValuesSelectorをラップしている
export const adjustedAdvancedValuesSelector: any = createDeepEqualSelector(
  shallEqualAdjustedAdvancedValuesSelector,
  (values) => values
);

export const advancedSearchFormInitialValuesSelector: any = createSelector(
  flightIndexParamSelector,
  (param: FlightIndexParam): any => {
    const initialDepartureHour: any = { from: 0, to: 24 };
    const hourInitialValues: () => any = (): any => {
      return {
        departure_hour1:
          param.departureHour1 && Object.keys(param.departureHour1).length
            ? param.departureHour1
            : initialDepartureHour,
        departure_hour2:
          param.departureHour2 && Object.keys(param.departureHour2).length
            ? param.departureHour2
            : initialDepartureHour,
        departure_hour3:
          param.departureHour3 && Object.keys(param.departureHour3).length
            ? param.departureHour3
            : initialDepartureHour,
        departure_hour4:
          param.departureHour4 && Object.keys(param.departureHour4).length
            ? param.departureHour4
            : initialDepartureHour,
        departure_hour5:
          param.departureHour5 && Object.keys(param.departureHour5).length
            ? param.departureHour5
            : initialDepartureHour,
        last_departure_hour:
          param.lastDepartureHour && Object.keys(param.lastDepartureHour).length
            ? param.lastDepartureHour
            : initialDepartureHour,
        last_arrival_hour:
          param.lastArrivalHour && Object.keys(param.lastArrivalHour).length
            ? param.lastArrivalHour
            : initialDepartureHour,
        min_layover:
          param.minLayover || param.minLayover === 0 ? param.minLayover : null,
        max_layover:
          param.maxLayover || param.maxLayover === 0 ? param.maxLayover : null,
      };
    };
    return {
      min_price: param.minPrice ? param.minPrice : initialMinPrice,
      max_price: param.maxPrice ? param.maxPrice : initialMaxPrice,
      max_stop: param.maxStop != null ? String(param.maxStop) : null,
      ...hourInitialValues(),
      airline_codes: param.airlineCodes ? param.airlineCodes : [],
      departure_airport_codes: param.departureAirportCodes
        ? param.departureAirportCodes
        : [],
      transit_airport_codes: param.transitAirportCodes
        ? param.transitAirportCodes
        : [],
      free_baggage_type: param.freeBaggageType ? param.freeBaggageType : null,
      not_use_lcc: !!param.notUseLcc,
    };
  }
);

/** action export **/
export const {
  setSearchFlightConditionValues,
  setSearchFlightAdvancedConditionValues,
  setSearchFlightSortConditionValues,
} = searchFlightSlice.actions;

export const convertSearchFlightFormValuesToParams = (data: any) => {
  const params = clone(data);
  if (params.flight_type === FlightTypes.multiCity) {
    delete params.departure_date;
    delete params.return_date;
  } else if (params.flight_type === FlightTypes.oneWay) {
    delete params.return_date;
    delete params.itineraries;
  } else {
    delete params.itineraries;
  }
  const agesOfChildren = params.traveler?.ages_of_children;
  if (agesOfChildren && agesOfChildren.length > 0) {
    params.traveler.ages_of_children = agesOfChildren.map((age) => age.age);
  } else {
    delete params.traveler?.ages_of_children;
  }
  return params;
};
