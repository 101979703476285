import * as React from "react";
import { css } from "aphrodite";
import { LabelStyle } from "./LabelStyle";

export interface ILabelProps {
  children: React.ReactNode;
  styles?: any;
}

export const Label: React.FC<ILabelProps> = (
  props: ILabelProps
): React.ReactElement<ILabelProps> => {
  return (
    <label className={css(LabelStyle.label, props.styles)}>
      {props.children}
    </label>
  );
};
