import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { camelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { Post } from "main/javascripts/types/post";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { EmailUserState } from "main/javascripts/types/emailUser";
import { User } from "main/javascripts/types/user";

const key = "emailUser";

const initialState: EmailUserState = {
  errors: null,
  loading: false,
};

/** Async **/
export const authEmailChatUnit = createAsyncThunk<
  {
    user: User.User;
  },
  {
    email: string;
    password: string;
    chatUnitToken: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/authEmailChatUnit`, async (args, { rejectWithValue }) => {
  try {
    const { email, password, chatUnitToken } = args;
    // console.log(headers);
    const result = await createWrapper().post(
      `${PATH_SCOPE}/api/email_chat_unit_authentications.json`,
      {
        email: email,
        password: password,
        chat_unit_token: chatUnitToken,
      }
    );
    // console.log(result);
    return {
      user: result.data.user,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const emailUserSlice = createSlice({
  name: key,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: { [key]: Post.PostEntityState }) => state[key];

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);
