import * as React from "react";

export function nl2br(text: string): any {
  if (!text) {
    return text;
  }
  const regex: any = /(\n)/g;
  return text.split(regex).map((line: string, index: number) => {
    if (line.match(regex)) {
      return <br key={index} />;
    }
    return line;
  });
}

export function br2nl(text: string): any {
  if (!text) {
    return text;
  }
  return text.replace(/<br\s*\/?>/gm, "\n");
}

export function zeroFill(num: number | string, length: number): string {
  // tslint:disable-next-line:prefer-array-literal
  return `${Array(length).join("0")}${num}`.slice(-length);
}

export function numberToCurrency(num: number, currency: string): string {
  const currencyDisplay: string = currency === "JPY" ? "name" : "symbol";
  return new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency,
    currencyDisplay,
  }).format(num);
}

export function url2link(text: any): any {
  if (typeof text !== "string") {
    return text;
  }

  const regex: any = /(https?:\/\/.+)/;
  return text.split(regex).map((line: any, index: number) => {
    const url: any = line.match(regex);
    if (url && url[1]) {
      return (
        <a
          href={url[1]}
          rel="nofollow noreferrer noopener"
          target="_blank"
          key={index}
        >
          {url[1]}
        </a>
      );
    }
    return line;
  });
}
