import { decamelizeKeys } from "humps";
import { getCookie } from "./cookieUtil";

export const getUtmValues = () => {
  const utmCampaign = getCookie("utm_campaign");
  const utmMedium = getCookie("utm_medium");
  const utmSource = getCookie("utm_source");

  const values = {
    ...(utmCampaign && { utmCampaign }),
    ...(utmMedium && { utmMedium }),
    ...(utmSource && { utmSource }),
  };

  return decamelizeKeys(values);
};
