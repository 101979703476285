import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { camelizeKeys } from "humps";
import { stringify } from "qs";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { Post } from "main/javascripts/types/post";
import { RootState } from "main/javascripts/store";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";

const key = "cms";
const adapter = createEntityAdapter<Post.Post>();

// Postとほぼ内容が同じなためPostEntityStateを使用している
// Cms独自のデータ管理が必要になった場合はCmsEntityStateを作成するか、
// 独自のStateを定義してその中にPostEntityStateを含める
const initialState: Post.PostEntityState = adapter.getInitialState({
  errors: null,
  postId: undefined,
  page: 0,
  numPerPage: 0,
  totalNum: 0,
  isLastPage: false,
  tag: null,
  region: null,
  rankings: [],
  popularTags: [],
  loading: false,
  postUpdate: null,
});

/** Async **/
export const fetchCmsIndexPage = createAsyncThunk<
  {
    posts: Post.Post[];
    totalNum: number;
    page: number;
    numPerPage: number;
  },
  {
    param?: string;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchCmsIndexPage`, async (args, { rejectWithValue }) => {
  try {
    const { param, isServerSide = true, headers } = args;
    const url = `${PATH_SCOPE}/api/cms.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      posts: result.data.posts,
      totalNum: result.data.totalNum,
      page: result.data.page,
      numPerPage: result.data.numPerPage,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const fetchCmsPosts = createAsyncThunk<
  {
    posts: Post.Post[];
    totalNum: number;
    page: number;
    numPerPage: number;
  },
  {
    param?: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchCmsPosts`, async (args, { rejectWithValue }) => {
  try {
    const { param } = args;
    const url = `${PATH_SCOPE}/api/cms/posts.json?${param}`;
    const result = await createWrapper().get(url);
    return {
      posts: result.data.posts,
      totalNum: result.data.totalNum,
      page: result.data.page,
      numPerPage: result.data.numPerPage,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

// 現在未使用
// 記事編集ページをredux toolkit化した際に使用する
export const fetchPostSuggestions = createAsyncThunk<
  {
    posts: Post.Post[];
  },
  {
    title: string;
    limit: number;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchPostSuggestions`, async (args, { rejectWithValue }) => {
  try {
    const { title, limit } = args;
    const paramString = stringify(
      { title, limit },
      { arrayFormat: "brackets" }
    );
    const url = `/api/posts/suggests.json?${paramString}`;
    const result = await createWrapper().get(url);
    return {
      posts: result.data,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

// 現在未使用
// 記事編集ページをredux toolkit化した際に使用する
export const postCmsRelatedPosts = createAsyncThunk<
  {
    post: Post.Post;
  },
  {
    postId: number;
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/postCmsRelatedPosts`, async (args, { rejectWithValue }) => {
  try {
    const { postId, params } = args;
    const url = `${PATH_SCOPE}/api/cms/posts/${postId}/related_posts.json`;
    const result = await createWrapper().post(url, params);
    return {
      post: result.data.post,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const deleteCmsPost = createAsyncThunk<
  {
    id: number;
  },
  {
    postId: number;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/deleteCmsPost`, async (args, { rejectWithValue }) => {
  try {
    const { postId } = args;
    const url = `${PATH_SCOPE}/api/cms/posts/${postId}.json`;
    await createWrapper().delete(url);
    return {
      id: postId,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const fetchCmsPostUpdate = createAsyncThunk<
  {
    postUpdate: Post.PostUpdate;
  },
  {
    postId: number;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchCmsPostUpdate`, async (args, { rejectWithValue }) => {
  try {
    const { postId } = args;
    const url = `${PATH_SCOPE}/api/cms/posts/${postId}/post_update.json`;
    const result = await createWrapper().get(url);
    return {
      postUpdate: result.data.postUpdate,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const putCmsPostUpdate = createAsyncThunk<
  {
    postUpdate: Post.PostUpdate;
  },
  {
    postId: number;
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/putCmsPostUpdate`, async (args, { rejectWithValue }) => {
  try {
    const { postId, params } = args;
    const url = `${PATH_SCOPE}/api/cms/posts/${postId}/post_update.json`;
    const result = await createWrapper().put(url, {
      post_update: params,
    });
    return {
      postUpdate: result.data.postUpdate,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const deleteCmsPostUpdate = createAsyncThunk<
  {
    id: number;
  },
  {
    postId: number;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/deleteCmsPostUpdate`, async (args, { rejectWithValue }) => {
  try {
    const { postId } = args;
    const url = `${PATH_SCOPE}/api/cms/posts/${postId}/post_update.json`;
    await createWrapper().delete(url);
    return {
      id: postId,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const cmsSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initPosts: (state, action) => {
      adapter.setAll(state, action.payload.posts);
      state.totalNum = action.payload.totalNum;
      state.page = action.payload.page;
      state.numPerPage = action.payload.numPerPage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCmsIndexPage.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload.posts);
        state.totalNum = action.payload.totalNum;
        state.page = action.payload.page;
        state.numPerPage = action.payload.numPerPage;
      })
      .addCase(fetchCmsPosts.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload.posts);
        state.totalNum = action.payload.totalNum;
        state.page = action.payload.page;
        state.numPerPage = action.payload.numPerPage;
      })
      .addCase(fetchCmsPostUpdate.fulfilled, (state, action) => {
        state.postUpdate = action.payload.postUpdate;
      })
      .addCase(putCmsPostUpdate.fulfilled, (state, action) => {
        state.postUpdate = action.payload.postUpdate;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
export const {
  selectById: postByIdSelector,
  selectIds: postIdsSelector,
  selectEntities: postEntitiesSelector,
  selectAll: postsSelector,
  selectTotal: totalPostsSelector,
} = adapter.getSelectors((state: RootState) => state.cms);

const stateSelector = (state: { [key]: Post.PostEntityState }) => state[key];

export const postIdSelector = createSelector(
  stateSelector,
  (state) => state.postId
);

export const postSelector = createSelector(
  [postEntitiesSelector, postIdSelector],
  (entities, postId) => {
    return entities[postId];
  }
);

export const postUpdateSelector = createSelector(
  stateSelector,
  (state) => state.postUpdate
);

export const totalNumSelector = createSelector(
  stateSelector,
  (state) => state.totalNum
);

export const pageSelector = createSelector(
  stateSelector,
  (state) => state.page
);

export const numPerPageSelector = createSelector(
  stateSelector,
  (state) => state.numPerPage
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const { initPosts } = cmsSlice.actions;
