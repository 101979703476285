import {
  createSlice,
  createEntityAdapter,
  createSelector,
  // createAsyncThunk,
} from "@reduxjs/toolkit";
// import { createWrapper } from "main/javascripts/api/AxiosWrapper";
// import { ErrorResponse } from "main/javascripts/types/errorResponse";
import { User } from "main/javascripts/types/user";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { fetchAccountParams } from "./accountParamSlice";
import { authEmailChatUnit } from "../features/emailUser/emailUserSlice";

const key = "currentUser";
const adapter = createEntityAdapter<User.User>();

const initialState: User.UserEntityState = adapter.getInitialState({
  currentUser: null,
  errors: null,
  loading: false,
});

/** Async **/

/** slice **/
export const currentUserSlice = createSlice({
  name: key,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountParams.fulfilled, (state, action) => {
        state.currentUser = action.payload.currentUser;
      })
      .addCase(authEmailChatUnit.fulfilled, (state, action) => {
        state.currentUser = action.payload.user;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state) => {
        // state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: { [key]: User.UserEntityState }) => state[key];

export const currentUserSelector = createSelector(
  stateSelector,
  (state) => state.currentUser
);
