import { StyleSheet } from "aphrodite";
import {
  fontSize,
  lineHeight,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { textColor } from "main/javascripts/styles/base/colorStyle";

export interface IStyle {
  label: any;
}

export const LabelStyle: IStyle = StyleSheet.create({
  label: {
    fontSize: fontSize.caption,
    lineHeight: lineHeight.caption1,
    fontWeight: fontWeight.bold,
    color: textColor.primaryDarkColor,
    display: "block",
    whiteSpace: "nowrap",
    margin: 0,
  },
});
