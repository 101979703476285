import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
  // createEntityAdapter,
} from "@reduxjs/toolkit";
import { stringify } from "qs";
import { camelizeKeys, decamelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { Lp } from "main/javascripts/types/lp";

const key = "lp";

const initialState: Lp.LpEntityState = {
  lpContent: null,
  lineRegisterUrl: "",
  loading: false,
  errors: null,
};

/** Async **/
export const fetchLpContent = createAsyncThunk<
  {
    lpContent: Lp.LpContent;
  },
  {
    cityPath: string;
    params: any;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchLpContent`, async (args, { rejectWithValue }) => {
  try {
    const { cityPath, params, isServerSide = false, headers } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/lps/${cityPath}.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      lpContent: result.data.lpContent,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const lpSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initLpContent: (state, action: PayloadAction<Lp.LpContent>) => {
      state.lpContent = action.payload;
    },
    setLineRegisterUrl: (state, action) => {
      state.lineRegisterUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLpContent.fulfilled, (state, action) => {
        state.lpContent = action.payload.lpContent;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

const stateSelector = (state: { [key]: Lp.LpEntityState }) => state[key];

export const lpContentSelector = createSelector(
  stateSelector,
  (state) => state.lpContent
);

export const lineRegisterUrlSelector = createSelector(
  stateSelector,
  (state) => state.lineRegisterUrl
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const { initLpContent, setLineRegisterUrl } = lpSlice.actions;
