import { currentUserSlice } from "main/javascripts/slices/currentUserSlice";
import { accountParamSlice } from "main/javascripts/slices/accountParamSlice";
import { cmsSlice } from "main/javascripts/features/cms/cmsSlice";
import { postSlice } from "main/javascripts/features/post/postSlice";
import { localeSlice } from "main/javascripts/slices/localeSlice";
import { searchHotelSlice } from "main/javascripts/features/hotel/searchHotelSlice";
import { searchFlightSlice } from "main/javascripts/features/flight/searchFlightSlice";
import { hotelSuggestionSlice } from "main/javascripts/features/hotel/hotelSuggestionSlice";
import { flightSuggestionSlice } from "main/javascripts/features/flight/flightSuggestionSlice";
import { hotelSlice } from "main/javascripts/features/hotel/hotelSlice";
import { flightSlice } from "main/javascripts/features/flight/flightSlice";
import { searchPageSlice } from "main/javascripts/features/search/searchPageSlice";
import { paramSlice } from "main/javascripts/slices/paramSlice";
import { lpSlice } from "main/javascripts/features/lp/lpSlice";
import { lpHotelDstRegionSlice } from "main/javascripts/features/lp/hotelDstRegion/lpHotelDstRegionSlice";
import { lpFlightDstRegionSlice } from "main/javascripts/features/lp/flightDstRegion/lpFlightDstRegionSlice";
import { emailUserSlice } from "main/javascripts/features/emailUser/emailUserSlice";
//
import { hotelReducer } from "./reducers/HotelReducer";
import { restaurantReducer } from "./reducers/RestaurantReducer";
import { activityReducer } from "./reducers/ActivityReducer";
import { destinationSuggestReducer } from "./reducers/DestinationSuggestReducer";
import { paramReducer } from "./reducers/ParamReducer";
import { accountParamsReducer } from "./reducers/AccountParamsReducer";
import { hotelReservationReducer } from "./reducers/HotelReservationReducer";
import { flightReservationReducer } from "./reducers/FlightReservationReducer";
import { reservationReducer } from "./reducers/ReservationReducer";
import { hotelPreReservationReducer } from "./reducers/HotelPreReservationReducer";
import { flightPreReservationReducer } from "./reducers/FlightPreReservationReducer";
import { currentUserReducer } from "./reducers/CurrentUserReducer";
import { requestReducer } from "./reducers/RequestReducer";
import { searchPageReducer } from "./reducers/SearchPageReducer";
import { flightOriginDestinationSuggestReducer } from "./reducers/FlightOriginDestinationSuggestReducer";
import { flightReducer } from "./reducers/FlightReducer";
import { breakpointReducer } from "./reducers/BreakpointReducer";
import { countryReducer } from "./reducers/CountryReducer";
import { localeReducer } from "./reducers/LocaleReducer";
import { dynamicPackagePreReservationReducer } from "./reducers/DynamicPackagePreReservationReducer";
import { dynamicPackageReservationReducer } from "./reducers/DynamicPackageReservationReducer";
import { carouselFlightReducer } from "./reducers/CarouselFlightReducer";
import { consultationConditionReducer } from "./reducers/ConsultationConditionReducer";
import { consultationReducer } from "./reducers/ConsultationReducer";
import { searchFlightReducer } from "./reducers/SearchFlightReducer";
import { searchHotelReducer } from "./reducers/SearchHotelReducer";
import { socialMediaStatusReducer } from "./reducers/SocialMediaStatusReducer";
import { constantReducer } from "./reducers/ConstantReducer";
import { messageReducer } from "./reducers/MessageReducer";
import { postReducer } from "./reducers/PostReducer";
import { editorReducer } from "./reducers/EditorReducer";
import { regionReducer } from "./reducers/RegionReducer";
import { chargeInfoReducer } from "./reducers/ChargeInfoReducer";

export type ReduxState = {
  currentUser: typeof currentUserSlice.reducer;
  accountParam: typeof accountParamSlice.reducer;
  cms: typeof cmsSlice.reducer;
  post: typeof postSlice.reducer;
  locale: typeof localeSlice.reducer;
  searchHotel: typeof searchHotelSlice.reducer;
  searchFlight: typeof searchFlightSlice.reducer;
  hotelSuggestion: typeof hotelSuggestionSlice.reducer;
  flightSuggestion: typeof flightSuggestionSlice.reducer;
  hotel: typeof hotelSlice.reducer;
  flight: typeof flightSlice.reducer;
  searchPage: typeof searchPageSlice.reducer;
  param: typeof paramSlice.reducer;
  lp: typeof lpSlice.reducer;
  lpHotelDstRegion: typeof lpHotelDstRegionSlice.reducer;
  lpFlightDstRegion: typeof lpFlightDstRegionSlice.reducer;
  emailUser: typeof emailUserSlice.reducer;
  // TODO 旧reducerを読み込んでいる
  hotelReducer: any;
  restaurantReducer: any;
  activityReducer: any;
  destinationSuggestReducer: any;
  paramReducer: any;
  accountParamsReducer: any;
  hotelReservationReducer: any;
  flightReservationReducer: any;
  reservationReducer: any;
  hotelPreReservationReducer: any;
  flightPreReservationReducer: any;
  currentUserReducer: any;
  requestReducer: any;
  searchPageReducer: any;
  flightOriginDestinationSuggestReducer: any;
  flightReducer: any;
  breakpointReducer: any;
  countryReducer: any;
  localeReducer: any;
  dynamicPackagePreReservationReducer: any;
  dynamicPackageReservationReducer: any;
  carouselFlightReducer: any;
  consultationConditionReducer: any;
  consultationReducer: any;
  searchFlightReducer: any;
  searchHotelReducer: any;
  socialMediaStatusReducer: any;
  constantReducer: any;
  messageReducer: any;
  postReducer: any;
  editorReducer: any;
  regionReducer: any;
  chargeInfoReducer: any;
};

export const rootReducer: ReduxState = {
  currentUser: currentUserSlice.reducer,
  accountParam: accountParamSlice.reducer,
  cms: cmsSlice.reducer,
  post: postSlice.reducer,
  locale: localeSlice.reducer,
  searchHotel: searchHotelSlice.reducer,
  searchFlight: searchFlightSlice.reducer,
  hotelSuggestion: hotelSuggestionSlice.reducer,
  flightSuggestion: flightSuggestionSlice.reducer,
  hotel: hotelSlice.reducer,
  flight: flightSlice.reducer,
  searchPage: searchPageSlice.reducer,
  param: paramSlice.reducer,
  lp: lpSlice.reducer,
  lpHotelDstRegion: lpHotelDstRegionSlice.reducer,
  lpFlightDstRegion: lpFlightDstRegionSlice.reducer,
  emailUser: emailUserSlice.reducer,
  hotelReducer,
  restaurantReducer,
  activityReducer,
  paramReducer,
  accountParamsReducer,
  destinationSuggestReducer,
  hotelReservationReducer,
  hotelPreReservationReducer,
  flightPreReservationReducer,
  flightReservationReducer,
  reservationReducer,
  currentUserReducer,
  requestReducer,
  searchPageReducer,
  flightOriginDestinationSuggestReducer,
  flightReducer,
  breakpointReducer,
  countryReducer,
  localeReducer,
  dynamicPackagePreReservationReducer,
  dynamicPackageReservationReducer,
  carouselFlightReducer,
  consultationConditionReducer,
  consultationReducer,
  searchFlightReducer,
  searchHotelReducer,
  socialMediaStatusReducer,
  constantReducer,
  messageReducer,
  postReducer,
  editorReducer,
  regionReducer,
  chargeInfoReducer,
};
